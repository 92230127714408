<template xmlns:wire="http://www.w3.org/1999/xhtml">
  <div class="">
    <!--  ActionDefineExcel上傳設置跳窗 (z同級的時候，越前面會在下層)  -->
    <div v-if="showActionDefineUploadExcelDialogToApp" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">標籤定義Excel上傳至App端</h2>
        </div>
        <div>
          <label class="text-2xl block text-o_purple-200 text-sm font-bold mb-2" for="file_input">上傳檔案須為.xlsx檔案(先從標籤表單下載標準格式)</label>
          <input class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="file_input" type="file" accept=".xlsx" @change="handleExcelActionDefineFileUpload">
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showActionDefineUploadExcelDialogToApp = !showActionDefineUploadExcelDialogToApp" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="uploadActionDefineExcelToApp" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            上傳
          </button>
        </div>
      </div>
    </div>
    <!--  刪除一般標籤確認清單  -->
    <div v-if="showDeleteActionByIdConfirmDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">刪除指定ID之履歷標籤</h2>
        </div>
        <div class="flex flex-col items-start text-center">
          <h2 class="text-xl font-semibold text-o_purple-100 leading-relaxed">標籤之ID:{{selectedDeleteActionID}} </h2>
          <h2 class="text-xl font-semibold text-o_purple-100 leading-relaxed">標籤之名稱:{{selectedDeleteActionName}} </h2>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showDeleteActionByIdConfirmDialog = !showDeleteActionByIdConfirmDialog, selectedDeleteActionID = 0, selectedDeleteActionName = ''" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="hardDeleteAction(selectedDeleteActionID)" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--  刪除超級標籤確認清單  -->
    <div v-if="showDeleteSuperActionByIdConfirmDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10">
        <div class="flex flex-col items-center text-center">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">刪除指定ID之履歷超級標籤</h2>
        </div>
        <div class="flex flex-col items-start text-center">
          <h2 class="text-xl font-semibold text-o_purple-100 leading-relaxed">超級標籤之ID:{{selectedDeleteSuperActionID}} </h2>
          <h2 class="text-xl font-semibold text-o_purple-100 leading-relaxed">超級標籤之名稱:{{selectedDeleteSuperActionName}} </h2>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showDeleteSuperActionByIdConfirmDialog = !showDeleteSuperActionByIdConfirmDialog, selectedDeleteSuperActionID = 0, selectedDeleteSuperActionName = ''" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="hardDeleteSuperAction(selectedDeleteSuperActionID)" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!-- ------------------------- 超級標籤建立的內容--------------------------   -->
    <div v-if="showSuperActionCreateDialog" class="fixed inset-0 flex items-center justify-center z-40 bg-gray-700 opacity-95">
      <div class="custom-task-dialog-h overflow-auto tableScrollbar flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100 px-10 w-1/3 h-4/5">
        <div class="flex flex-col items-center text-center mb-2">
          <h2 class="mt-1 text-3xl font-semibold text-o_purple-100 leading-relaxed">超級標籤設置</h2>
        </div>
<!--        <div class="mb-2">-->
<!--          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">預定日期</label>-->
<!--          &lt;!&ndash; 與定植日期相反，min設置即當日(利用:minSetTaskAndToDoDay取得)，因為當日是最小尚未發生日(正在進行) &ndash;&gt;-->
<!--          <input v-model="dateSelectForTaskAndTodo" :min="minSetTaskAndToDoDay" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="date" required>-->
<!--        </div>-->
        <!--  tag1下拉產品批號選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">產品批號</label>
        <div class="relative inline-block text-left z-50">
          <div class="mb-2">
            <button v-on:click="superAction_tag1_product_codeActive = !superAction_tag1_product_codeActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_superAction_tag1_product_code_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="superAction_tag1_product_codeActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectProductCodeItemEvent(superAction_tag1_action.action_id, $event)"
                   :data-id="superAction_tag1_action.id"
                   v-for="(superAction_tag1_action, superAction_tag1_action_index) in superAction_tag1_product_code_actions" :key="superAction_tag1_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ superAction_tag1_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag1下拉產品批號選單end      -->
        <!--  tag2下拉操作大項選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作大項</label>
        <div class="relative inline-block text-left z-40">
          <div class="mb-2">
            <button v-on:click="superAction_tag2_operationActive = !superAction_tag2_operationActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_superAction_tag2_operation_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="superAction_tag2_operationActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectOperationItemEvent(superAction_tag2_action.action_id, $event)"
                   v-for="(superAction_tag2_action, superAction_tag2_action_index) in superAction_tag2_operation_actions" :key="superAction_tag2_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ superAction_tag2_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag2下拉操作大項選單end      -->
        <!--  tag3下拉操作細節選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作細節</label>
        <div class="relative inline-block text-left z-30">
          <div class="mb-2">
            <button v-on:click="superAction_tag3_detailActive = !superAction_tag3_detailActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_superAction_tag3_detail_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="superAction_tag3_detailActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectDetailItemEvent(superAction_tag3_action.action_id, $event)"
                   v-for="(superAction_tag3_action, superAction_tag3_action_index) in superAction_tag3_detail_actions" :key="superAction_tag3_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ superAction_tag3_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag3下拉操作細節選單end      -->
        <!--  tag4下拉操作地點選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">操作地點</label>
        <div class="relative inline-block text-left z-20">
          <div class="mb-2">
            <button v-on:click="superAction_tag4_placeActive = !superAction_tag4_placeActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_superAction_tag4_place_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="superAction_tag4_placeActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectPlaceItemEvent(superAction_tag4_action.action_id, $event)"
                   v-for="(superAction_tag4_action, superAction_tag4_action_index) in superAction_tag4_place_actions" :key="superAction_tag4_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ superAction_tag4_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag4下拉操作地點選單end      -->
        <!--  tag5下拉產品名稱選單start      -->
        <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">產品名稱</label>
        <div class="relative inline-block text-left z-10">
          <div class="mb-2">
            <button v-on:click="superAction_tag5_productActive = !superAction_tag5_productActive" type="button"
                    class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
              {{ select_superAction_tag5_product_state }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
            <!--
              Dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div v-show="superAction_tag5_productActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
              <div class="py-1 max-h-64 overflow-y-auto tableScrollbar" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <!--  @click.prevent可以防止防止a原本默認的行為，而他會造成畫面滾動到頂(但我們實際上沒有要a 的既有功能，採用@click則會有該現象 -->
                <a @click.prevent="selectProductItemEvent(superAction_tag5_action.action_id, $event)"
                   v-for="(superAction_tag5_action, superAction_tag5_action_index) in superAction_tag5_product_actions" :key="superAction_tag5_action_index"
                   href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                  {{ superAction_tag5_action.name }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!--  tag5下拉產品名稱選單end      -->
        <!--  數值輸入，只有數值標籤才會顯示內定0 end      -->
<!--        <div v-show="showTaskValueInput" class="mb-2">-->
<!--          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-1" for="date">數值輸入-單位:{{valueUnit}}</label>-->
<!--          <input v-model="task_value" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="date" type="number" required>-->
<!--        </div>-->
        <!--  數值輸入，只有數值標籤才會顯示內定0 end      -->
        <div>
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-2" for="super_name">超級標籤名稱</label>
          <textarea v-model="setSuperActionName" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="super_name" rows="2" required></textarea>
        </div>
        <div>
          <label class="text-xl block text-o_purple-200 text-sm font-bold mb-2" for="super_content">超級標籤說明</label>
          <textarea v-model="setSuperActionContent" class="text-xl text-o_purple-100 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="super_content" rows="3" required></textarea>
        </div>
        <div class="flex flex-row mt-1 mb-2 grid grid-cols-2 gap-3">
          <button @click="showSuperActionCreateDialog = !showSuperActionCreateDialog" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-white border-2 border-o_purple-200 hover:bg-gray-50 text-o_purple-200 text-sm font-medium rounded-md">
            取消
          </button>
          <button @click="SendSuperActionDialogToDBConfirmClick" class="text-xl font-semibold col-span-1 px-4 py-1.5 mt-2 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--  提醒廣播設置跳窗  -->
    <div v-if="showNotificationDialog" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-700 opacity-95">
      <div class="w-3/5 flex flex-col p-5 rounded-lg shadow bg-white border-2 border-gray-100 opacity-100">
        <div class="flex flex-col items-center text-center">
          <div class="inline-block p-4 bg-o_purple-100 rounded-full">
            <svg class="w-12 h-12 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"/></svg>
          </div>
          <h2 class="mt-2 text-xl font-semibold text-gray-800">提示訊息</h2>
          <p class="mt-2 text-xl font-semibold text-o_purple-100 leading-relaxed">
            {{ formattedMessage }}
          </p>
        </div>

        <div class="flex items-center mt-3">
          <button @click="showNotificationDialog = !showNotificationDialog" class="flex-1 px-4 py-2 mt-2 mx-10 bg-o_purple-100 hover:bg-o_purple-200 text-white text-sm font-medium rounded-md">
            確定
          </button>
        </div>
      </div>
    </div>
    <!--  讀取等待  -->
    <div v-show="isLoading" wire:loading class="z-50 fixed top-0 left-0 right-0 bottom-0 w-full h-screen overflow-hidden bg-gray-700 opacity-80 flex flex-col items-center justify-center">
      <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 class="text-center text-white text-xl font-semibold">處理中...</h2>
      <p class="w-1/3 text-center text-white">這需要一些時間, 請不要關閉此頁面或做其他操作</p>
    </div>
    <!------- 所有標籤選單 --------------------------------------------->
      <!------- 按鈕都一樣，上傳新清單、新增超級標籤都會在，另外有一個重新整理 -->
    <div v-show="true" class="bg-gray-50 sticky top-0 z-20">
      <div class="sticky top-0 bg-gray-50 border-b pb-1.5 z-20">
        <div class="flex flex-row items-center ml-4">
          <label id="listbox-label1" class="fixed block relative text-2xl text-o_purple-100 font-semibold mr-2">
            標籤種類選項:
          </label>
          <div class="relative inline-block text-left">
            <div>
              <button v-on:click="typeFilterActive = !typeFilterActive" type="button"
                      class="text-o_purple-100 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-0.5 bg-white text-xl font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-o_purple-100" id="options-menu1" aria-haspopup="true" aria-expanded="true">
                {{ selectTypeState }}
                <!-- Heroicon name: solid/chevron-down -->
                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
              <!--
                Dropdown panel, show/hide based on dropdown state.

                Entering: "transition ease-out duration-100"
                  From: "transform opacity-0 scale-95"
                  To: "transform opacity-100 scale-100"
                Leaving: "transition ease-in duration-75"
                  From: "transform opacity-100 scale-100"
                  To: "transform opacity-0 scale-95"
              -->
              <div v-show="typeFilterActive" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <!-- 選單滑動要設定最大高度max-h-64(先以64px)，再設置overflow-y-auto，才會產生滾輪選單，最後設置tableScrollbar設置滾輪樣式 -->
                <div class="tableScrollbar py-1 max-h-64 overflow-y-auto" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  <a @click.prevent="typeSelectEvent"
                     v-for="type in actionTypes" :key="type"
                     href="#" class="block px-3 py-0.5 text-xl text-o_purple-100 hover:bg-gray-100 hover:text-gray-900" role="menuitem">
                    {{ type }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="getAppAuth"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            驗證
          </button>
          <button
            @click="removeAppAuth"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            清除
          </button>
          <button
            @click="getActionExcelDefineFromApp"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            標籤表單下載
          </button>
          <button
            @click="showUploadActionDefineExcelDialog"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            上傳標籤表單
          </button>
          <!-- 超級標籤，留待下期 -->
          <button
            @click="superActionButtonClick"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            新增超級標籤
          </button>
          <button
            @click="checkConnection"
            type="button"
            class=" ml-3 font-semibold
                  inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                  shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                  focus:ring-offset-2 focus:ring-o_purple-100">
            連線確認
          </button>
          <button
            @click="reloadPage('頁面重新整理')"
            type="button"
            class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
            重新整理
          </button>
        </div>
      </div>
      <div class="flex p-1 px-4 border-b">
          <label id="listbox-label1" class="block text-xl text-o_purple-100 font-semibold mr-2">
            App網絡連線狀態:
          </label>
          <span v-if="connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
           未連線
          </span>
          <span v-if="!connected" class="px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            連線中
          </span>
      </div>
    </div>

    <!------- 所有標籤的Canvas------------------------------------------------------------------>
    <div class="tableScrollbar mx-3 mt-1 custom-table-inside overflow-x-scroll overflow-y-scroll flex flex-col bg-white shadow-2xl rounded-2xl">
      <div class="sm:-mx-6 lg:-mx-8 rounded-2xl">
        <div class="p-0 align-middle inline-block min-w-full sm:pl-6 lg:pl-8">
          <ul v-if="actionShow" role="list" class="grid grid-cols-1 gap-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-3 p-2">
            <!--   一般標籤，最外層因為是超級標籤切換，因此採v-show不重刷-->
            <li v-for="(action, actionIndex) in actionData" :key="'AD' + actionIndex" class="p-3 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200 shadow-lg">
              <div class="text-right mb-1 flex justify-between">
                <div class="break-words font-black text-o_purple-200 break-words inline text-right text-2xl">ID: {{action.action_id}}</div>
                <button
                  @click="showDeleteActionByIdConfirmDialog = !showDeleteActionByIdConfirmDialog, selectedDeleteActionID = action.action_id, selectedDeleteActionName = action.name"
                  type="button"
                  class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
                  刪除
                </button>
              </div>
              <div class="break-words font-black text-o_purple-200 break-words">名稱: <span class="font-bold text-o_purple-100">{{action.name}} </span></div>
              <div class="break-words font-black text-o_purple-200 break-words">類型: <span class="font-bold text-o_purple-100">{{action.tag_id}} </span></div>
              <qriously v-bind:value="action.action_id.toString()" :size="100" v-bind:background-alpha="1.0"/>
              <div v-if="coefActive" class="break-words font-black text-o_purple-200 break-words">係數: <span class="font-bold text-o_purple-100">{{action.coef}}</span></div>
              <div v-if="coefActive" class="break-words font-black text-o_purple-200 break-words">係數單位: <span class="font-bold text-o_purple-100">{{action.coef_unit}}</span></div>
<!--              <div v-if="coefActive" class="break-words font-black text-o_purple-200 break-words">ISO-14064類別: <span class="font-bold text-o_purple-100">{{''}}</span></div>-->
<!--              <div v-if="coefActive" class="break-words font-black text-o_purple-200 break-words">ISO-14064動作: <span class="font-bold text-o_purple-100">{{''}}</span></div>-->
              <div v-if="areaActive" class="break-words font-black text-o_purple-200 break-words">面積:<span class="font-bold text-o_purple-100">{{action.area}}</span></div>
              <div v-if="areaActive" class="break-words font-black text-o_purple-200 break-words">面積單位:<span class="font-bold text-o_purple-100">{{action.area_unit}}</span></div>
              <div class="break-words font-black text-o_purple-200 break-words">詳細說明
                  <span class="toggle inline pb-2" @click="actionDetailToggle">
                  <vue-chevron class="inline-block ml-1 mb-1 text-center"
                               :point-down="pointDown"
                               :duration="duration"
                               :thickness="thickness"
                               :angle="angle"
                  />
                </span>
              </div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">中文: <span class="font-bold text-o_purple-100">{{action.chinese}} </span></div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">英文: <span class="font-bold text-o_purple-100">{{action.english}} </span></div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">日文: <span class="font-bold text-o_purple-100">{{action.japanese}} </span></div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">說明: <span class="font-bold text-o_purple-100">{{action.content}} </span></div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">ISO-14064-1類別: <span class="font-bold text-o_purple-100">{{action.iso_140641_label}} </span></div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">ISO-14064-1代號: <span class="font-bold text-o_purple-100">{{action.iso_140641_code}} </span></div>
            </li>
          </ul>
          <ul v-if="superShow" role="list" class="grid grid-cols-1 gap-2 gap-y-2 sm:grid-cols-2 lg:grid-cols-3 p-2">
            <!--   超級標籤，最外層因為是超級標籤切換，因此採v-show不重刷    -->
            <li v-for="(superAction, superActionIndex) in superData" :key="'SD' + superActionIndex" class="p-2 col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
              <div class="text-right mb-1 flex justify-between">
                <div class="break-words font-black text-o_purple-200 break-words inline text-right text-2xl">ID: {{superAction.super_action_id}}</div>
                <div>
                  <button
                    @click="showDeleteSuperActionByIdConfirmDialog = !showDeleteSuperActionByIdConfirmDialog, selectedDeleteSuperActionID = superAction.super_action_id, selectedDeleteSuperActionName = superAction.name"
                    type="button"
                    class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
                    刪除
                  </button>
                  <button
                    @click="superActionButtonClickAtUpdateButton(superAction.action_set, superAction.name, superAction.content), selectedUpdateSuperActionId = superAction.super_action_id"
                    type="button"
                    class=" ml-3 font-semibold
                inline-flex items-center px-4 py-1 border border-transparent text-base font-medium rounded-md
                shadow-sm text-white bg-o_purple-100 hover:bg-o_purple-200 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-o_purple-100">
                    更新
                  </button>
                </div>
              </div>
              <div class="break-words font-black text-o_purple-200 break-words">名稱: <span class="font-bold text-o_purple-100">{{superAction.name}} </span></div>
              <div class="break-words font-black text-o_purple-200 break-words">組合: <span class="font-bold text-o_purple-100">{{superAction.action_set}} </span></div>
              <qriously v-bind:value="superAction.super_action_id.toString()" :size="100" v-bind:background-alpha="1.0"/>
              <div class="break-words font-black text-o_purple-200 break-words">詳細說明
                <span class="toggle inline pb-2" @click="actionDetailToggle">
                  <vue-chevron class="inline-block ml-1 mb-1 text-center"
                               :point-down="pointDown"
                               :duration="duration"
                               :thickness="thickness"
                               :angle="angle"
                  />
                </span>
              </div>
              <div v-if="detailActive" class="break-words font-black text-o_purple-200 break-words">說明: <span class="font-bold text-o_purple-100">{{superAction.content}} </span></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueChevron from 'vue-chevron'
import AuthServiceApp from '@/services/auth.service.app'
import UserService from '@/services/user.service'
export default {
  name: 'Action',
  components: {
    VueChevron
  },
  data () {
    return {
      // app連線確認，true是未連線
      connected: true,
      // auth、actionSuperMap取得狀態
      getAppAuthStatus: false,
      getActionSuperMapStatus: false,
      // action define upload in excel to db相關
      showActionDefineUploadExcelDialogToApp: false,
      selectedFile: null,
      // ----讀取等待-----------
      isLoading: false,
      // ----提醒廣播相關--------
      showNotificationDialog: false,
      formattedMessage: '',
      //  ------------------------
      // 超級標籤，留待下期
      actionTypes: ['產品批號', '操作大項', '操作細節', '操作地點', '產品名稱', '超級標籤'],
      // 超級標籤關閉選單
      // actionTypes: ['產品批號', '操作大項', '操作細節', '操作地點', '產品名稱'],
      typeFilterActive: false,
      selectTypeState: '請選擇標籤種類',
      actionSuperMapObj: {},
      actionData: [],
      superData: [],
      // action card呈現與super card呈現
      actionShow: false,
      superShow: false,
      // ------第三類標籤3 係數、係數單位active-----
      coefActive: false,
      // ------第四類標籤4 面積、面積單位active-----
      areaActive: false,
      // ------標籤細節相關------ (超級標籤沒牽涉這個)
      detailActive: false,
      pointDown: true,
      thickness: 8,
      duration: 300,
      angle: 40,
      // --------超級標籤建立/更新相關--------- superAction
      // create 超級標籤是false、update 超級標籤是true
      isCreateOrUpdateSuper: false,
      showSuperActionCreateDialog: false,
      currentClickSuperActionSet: [],
      setSuperActionName: '',
      setSuperActionContent: '',
      superAction_tag1_product_code_actions: [],
      superAction_tag2_operation_actions: [],
      superAction_tag3_detail_actions: [],
      superAction_tag4_place_actions: [],
      superAction_tag5_product_actions: [],
      select_superAction_tag1_product_code_actionid: '',
      select_superAction_tag2_operation_actionid: '',
      select_superAction_tag3_detail_actionid: '',
      select_superAction_tag4_place_actionid: '',
      select_superAction_tag5_product_actionid: '',
      // 待辦任務tag1-產品批號相關product_code
      select_superAction_tag1_product_code_state: '請選擇',
      superAction_tag1_product_codeActive: false,
      superAction_tag1_product_codeSelected: false,
      // 待辦任務tag2-產品操作大項相關operation
      select_superAction_tag2_operation_state: '請選擇',
      superAction_tag2_operationActive: false,
      superAction_tag2_operationSelected: false,
      // 待辦任務tag3-產品操作細節相關detail
      select_superAction_tag3_detail_state: '請選擇',
      superAction_tag3_detailActive: false,
      superAction_tag3_detailSelected: false,
      // 待辦任務tag4-產品操作地點相關place
      select_superAction_tag4_place_state: '請選擇',
      superAction_tag4_placeActive: false,
      superAction_tag4_placeSelected: false,
      // 待辦任務tag5-產品名稱相關product
      select_superAction_tag5_product_state: '請選擇',
      superAction_tag5_productActive: false,
      superAction_tag5_productSelected: false,
      // 刪除指定id之一般標籤相關--------------------------
      showDeleteActionByIdConfirmDialog: false,
      selectedDeleteActionID: 0,
      selectedDeleteActionName: '',
      // 刪除指定id之超級標籤相關--------------------------
      showDeleteSuperActionByIdConfirmDialog: false,
      selectedUpdateSuperActionId: 0,
      selectedDeleteSuperActionID: 0,
      selectedDeleteSuperActionName: ''
    }
  },
  async mounted () {
    this.isLoading = true
    // 初始化
    this.getAppAuthStatus = false
    this.getActionSuperMapStatus = false
    this.actionSuperMapObj = {}
    this.connected = true // 連線是true才為初始未連線
    // -----------------
    await this.getAppAuth()
    await this.getActionSuperMap()
    await UserService.checkAppConnection().then(
      res => {
        // console.log(this.content.err)
        this.connected = res.data.result.err
      }
    ).catch(err => {
      // console.log('Connection err')
      if (err.response) {
        // Request made and server responded 請求成立且回應有回來，是錯誤情況
        // 若沒有要鎖住畫面則可以註解或清除掉
        this.isLoading = false
        return false
      } else if (err.request) {
        // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
        this.isLoading = false
        return false
      } else {
        // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
        this.isLoading = false
        return false
      }
    })
    if (this.getAppAuthStatus && this.getActionSuperMapStatus) {
      this.setNotificationDialog('成功取得標籤定義與App端驗證')
    }
    this.isLoading = false
    // console.log(UserService.GetActionSuperMapFromApp())
  },
  methods: {
    // 超級標籤選擇選單相關--------------------
    // 任務設置之tag1: 產品批號選擇事件
    selectProductCodeItemEvent (id, event) {
      // console.log(id)
      this.select_superAction_tag1_product_code_actionid = id
      this.select_superAction_tag1_product_code_state = event.target.innerText
      this.superAction_tag1_product_codeActive = !this.superAction_tag1_product_codeActive
      this.superAction_tag1_product_codeSelected = true
    },
    // SuperAction設置之tag2: 操作大項選擇事件
    selectOperationItemEvent (id, event) {
      this.select_superAction_tag2_operation_actionid = id
      this.select_superAction_tag2_operation_state = event.target.innerText
      this.superAction_tag2_operationActive = !this.superAction_tag2_operationActive
      this.superAction_tag2_operationSelected = true
    },
    // SuperAction之tag3: 操作細節選擇事件(這跟待辦任務不同，沒有係數選填的情形)
    selectDetailItemEvent (id, event) {
      this.select_superAction_tag3_detail_actionid = id
      this.select_superAction_tag3_detail_state = event.target.innerText
      this.superAction_tag3_detailActive = !this.superAction_tag3_detailActive
      this.superAction_tag3_detailSelected = true
    },
    // SuperAction之tag4: 操作地點選擇事件
    selectPlaceItemEvent (id, event) {
      this.select_superAction_tag4_place_actionid = id
      this.select_superAction_tag4_place_state = event.target.innerText
      this.superAction_tag4_placeActive = !this.superAction_tag4_placeActive
      this.superAction_tag4_placeSelected = true
    },
    // SuperAction之tag5: 產品名稱選擇事件
    selectProductItemEvent (id, event) {
      this.select_superAction_tag5_product_actionid = id
      this.select_superAction_tag5_product_state = event.target.innerText
      this.superAction_tag5_productActive = !this.superAction_tag5_productActive
      this.superAction_tag5_productSelected = true
    },
    // -------------------------------------
    async checkConnection () {
      this.isLoading = true
      await UserService.checkAppConnection().then(
        res => {
          this.connected = res.data.result.err
        }
      ).catch(err => {
        // console.log('Connection err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      this.isLoading = false
    },
    superActionButtonClick () {
      // 這是設置create建立超級標籤因此設置false
      this.isCreateOrUpdateSuper = false
      // 新增超級標籤按鈕點了會進行初始化
      this.setSuperActionName = ''
      this.setSuperActionContent = ''
      // super action tag1-產品批號相關product_code
      this.select_superAction_tag1_product_code_state = '請選擇'
      this.superAction_tag1_product_codeActive = false
      this.superAction_tag1_product_codeSelected = false
      // super action tag2-產品操作大項相關operation
      this.select_superAction_tag2_operation_state = '請選擇'
      this.superAction_tag2_operationActive = false
      this.superAction_tag2_operationSelected = false
      // super action tag3-產品操作細節相關detail
      this.select_superAction_tag3_detail_state = '請選擇'
      this.superAction_tag3_detailActive = false
      this.superAction_tag3_detailSelected = false
      // super action tag4-產品操作地點相關place
      this.select_superAction_tag4_place_state = '請選擇'
      this.superAction_tag4_placeActive = false
      this.superAction_tag4_placeSelected = false
      // super action tag5-產品名稱相關product
      this.select_superAction_tag5_product_state = '請選擇'
      this.superAction_tag5_productActive = false
      this.superAction_tag5_productSelected = false
      // 選擇id、set初始化
      this.select_superAction_tag1_product_code_actionid = ''
      this.select_superAction_tag2_operation_actionid = ''
      this.select_superAction_tag3_detail_actionid = ''
      this.select_superAction_tag4_place_actionid = ''
      this.select_superAction_tag5_product_actionid = ''
      // 下面這個則是建置super action set採用的[]
      this.currentClickSuperActionSet = []
      // 點選的更新的super action []
      // this.currentClickUpdateSuperActionSet = [],
      // 初始化後打開設置任務跳窗
      this.showSuperActionCreateDialog = true
      // 後發生的跳窗若z層級一樣，會在上，不過這個z-50大於任務跳窗的z-40，所以在上
      this.setNotificationDialog('編輯超級標籤，能將五個類型標籤構成一筆紀錄，讓使用者能掃描一標籤建立履歷紀錄')
    },
    superActionButtonClickAtUpdateButton (currentClickUpdateSuperActionSet, actionName, actionContent) {
      // 這是設置update更新超級標籤因此設置true
      this.isCreateOrUpdateSuper = true
      // 新增超級標籤按鈕點了會進行初始化
      this.setSuperActionName = actionName
      this.setSuperActionContent = actionContent
      this.isLoading = true
      // 選擇id、set初始化 (會先看當前選擇更新的內容set是否都有對應，有對應就預先選)
      // super action tag1-產品批號相關product_code
      const foundTag1Action = this.superAction_tag1_product_code_actions.find(item => item.action_id === currentClickUpdateSuperActionSet[0])
      if (foundTag1Action) {
        this.select_superAction_tag1_product_code_actionid = foundTag1Action.action_id
        this.select_superAction_tag1_product_code_state = foundTag1Action.name
        this.superAction_tag1_product_codeSelected = true
      } else {
        this.select_superAction_tag1_product_code_actionid = ''
        this.select_superAction_tag1_product_code_state = '請選擇'
        this.superAction_tag1_product_codeActive = false
        this.superAction_tag1_product_codeSelected = false
      }
      // super action tag2-產品操作大項相關operation
      const foundTag2Action = this.superAction_tag2_operation_actions.find(item => item.action_id === currentClickUpdateSuperActionSet[1])
      if (foundTag2Action) {
        this.select_superAction_tag2_operation_actionid = foundTag2Action.action_id
        this.select_superAction_tag2_operation_state = foundTag2Action.name
        this.superAction_tag2_operationSelected = true
      } else {
        this.select_superAction_tag2_operation_actionid = ''
        this.select_superAction_tag2_operation_state = '請選擇'
        this.superAction_tag2_operationActive = false
        this.superAction_tag2_operationSelected = false
      }
      // super action tag3-產品操作細節相關detail
      const foundTag3Action = this.superAction_tag3_detail_actions.find(item => item.action_id === currentClickUpdateSuperActionSet[2])
      if (foundTag3Action) {
        this.select_superAction_tag3_detail_actionid = foundTag3Action.action_id
        this.select_superAction_tag3_detail_state = foundTag3Action.name
        this.superAction_tag3_detailSelected = true
      } else {
        this.select_superAction_tag3_detail_actionid = ''
        this.select_superAction_tag3_detail_state = '請選擇'
        this.superAction_tag3_detailActive = false
        this.superAction_tag3_detailSelected = false
      }
      // super action tag4-產品操作地點相關place
      const foundTag4Action = this.superAction_tag4_place_actions.find(item => item.action_id === currentClickUpdateSuperActionSet[3])
      if (foundTag4Action) {
        this.select_superAction_tag4_place_actionid = foundTag4Action.action_id
        this.select_superAction_tag4_place_state = foundTag4Action.name
        this.superAction_tag4_placeSelected = true
      } else {
        this.select_superAction_tag4_place_actionid = ''
        this.select_superAction_tag4_place_state = '請選擇'
        this.superAction_tag4_placeActive = false
        this.superAction_tag4_placeSelected = false
      }
      // super action tag5-產品名稱相關product
      const foundTag5Action = this.superAction_tag5_product_actions.find(item => item.action_id === currentClickUpdateSuperActionSet[4])
      if (foundTag5Action) {
        this.select_superAction_tag5_product_actionid = foundTag5Action.action_id
        this.select_superAction_tag5_product_state = foundTag5Action.name
        this.superAction_tag5_productSelected = true
      } else {
        this.select_superAction_tag5_product_actionid = ''
        this.select_superAction_tag5_product_state = '請選擇'
        this.superAction_tag5_productActive = false
        this.superAction_tag5_productSelected = false
      }
      this.isLoading = false
      // 初始化後打開設置任務跳窗
      this.showSuperActionCreateDialog = true
      // 後發生的跳窗若z層級一樣，會在上，不過這個z-50大於任務跳窗的z-40，所以在上
      this.setNotificationDialog('編輯超級標籤，能將五個類型標籤構成一筆紀錄，讓使用者能掃描一標籤建立履歷紀錄，若有顯示請選擇，那表示原本選擇標籤不存在、被註銷或不屬於該類別')
    },

    async SendSuperActionDialogToDBConfirmClick () {
      if (!(this.superAction_tag1_product_codeSelected && this.superAction_tag2_operationSelected && this.superAction_tag3_detailSelected && this.superAction_tag4_placeSelected && this.superAction_tag5_productSelected)) {
        this.setNotificationDialog('有尚未選擇標籤(顯示:請選擇)')
      } else if (this.setSuperActionName === '' || this.setSuperActionContent === '') {
        this.setNotificationDialog('超級標籤名稱、超級標籤說明尚未填寫，二者皆不可留白')
      } else {
        this.isLoading = true
        this.showSuperActionCreateDialog = !this.showSuperActionCreateDialog
        this.currentClickSuperActionSet = []
        this.currentClickSuperActionSet.push(this.select_superAction_tag1_product_code_actionid)
        this.currentClickSuperActionSet.push(this.select_superAction_tag2_operation_actionid)
        this.currentClickSuperActionSet.push(this.select_superAction_tag3_detail_actionid)
        this.currentClickSuperActionSet.push(this.select_superAction_tag4_place_actionid)
        this.currentClickSuperActionSet.push(this.select_superAction_tag5_product_actionid)
        this.setNotificationDialog(this.currentClickSuperActionSet)
        // true就執行update、false就執行create
        if (this.isCreateOrUpdateSuper) {
          await UserService.SendSuperInfoToAppBackendToBuildTaskAtUpdateButton(this.selectedUpdateSuperActionId, this.setSuperActionName, this.currentClickSuperActionSet, this.setSuperActionContent).then(
            res => {
              const sendSuperResult = res.data
              if (sendSuperResult.is_err) {
                // 這個的建立超級標籤後端不論錯誤與否都是回傳http statusOK 200，前端因此可以解讀到錯誤訊息
                this.isLoading = false
                this.setNotificationDialog('無法"更新"超級標籤，可能組合有過期、不存在的標籤內容或沒選擇，可重新整理後再建立，若無法排除將下列錯誤代號回報: ' + sendSuperResult.err)
              } else {
                this.isLoading = false
                this.setNotificationDialog('成功"更新"超級標籤，可於超級標籤清單去閱覽，超級標籤 id為: ' + sendSuperResult.result)
                this.reloadPage('成功"更新"超級標籤，超級標籤id為' + sendSuperResult.result)
              }
            }
          ).catch(err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.isLoading = false
              this.setNotificationDialog('"更新"超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.isLoading = false
              this.setNotificationDialog('"更新"超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.isLoading = false
              this.setNotificationDialog('"更新"超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            }
          })
        } else {
          // false下面執行create
          await UserService.SendSuperInfoToAppBackendToBuildTask(this.setSuperActionName, this.currentClickSuperActionSet, this.setSuperActionContent).then(
            res => {
              const sendSuperResult = res.data
              if (sendSuperResult.is_err) {
                // 這個的建立超級標籤後端不論錯誤與否都是回傳http statusOK 200，前端因此可以解讀到錯誤訊息
                this.isLoading = false
                this.setNotificationDialog('無法建立超級標籤，可能組合有過期、不存在的標籤內容或沒選擇，可重新整理後再建立，若無法排除將下列錯誤代號回報: ' + sendSuperResult.err)
              } else {
                this.isLoading = false
                this.setNotificationDialog('成功建立超級標籤，可於超級標籤清單去閱覽，超級標籤 id為: ' + sendSuperResult.result)
                this.reloadPage('成功建立超級標籤，超級標籤id為' + sendSuperResult.result)
              }
            }
          ).catch(err => {
            if (err.response) {
              // Request made and server responded 請求成立且回應有回來，是錯誤情況
              // 若沒有要鎖住畫面則可以註解或清除掉
              this.isLoading = false
              this.setNotificationDialog('建立超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            } else if (err.request) {
              // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
              this.isLoading = false
              this.setNotificationDialog('建立超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            } else {
              // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
              this.isLoading = false
              this.setNotificationDialog('建立超級標籤請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
              return false
            }
          })
        }
      }
    },
    async reloadPage (otherInfo) {
      this.isLoading = true
      // 初始化
      this.getAppAuthStatus = false
      this.getActionSuperMapStatus = false
      this.actionSuperMapObj = {}
      this.connected = true // 連線是true才為初始未連線
      // card初始化全部都false
      this.coefActive = false
      this.areaActive = false
      this.superShow = false
      this.actionShow = false
      this.detailActive = false
      this.pointDown = false
      // -----------------
      await this.getAppAuth()
      await this.getActionSuperMap()
      await UserService.checkAppConnection().then(
        res => {
          // console.log(this.content.err)
          this.connected = res.data.result.err
        }
      ).catch(err => {
        // console.log('Connection err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.isLoading = false
          return false
        }
      })
      if (this.getAppAuthStatus && this.getActionSuperMapStatus) {
        this.setNotificationDialog(otherInfo + ': 成功重新整理刷新標籤定義與App端驗證')
      }
      this.isLoading = false
    },
    async getActionSuperMap () {
      await UserService.GetActionSuperMapFromApp().then(
        res => {
          // console.log(res.data)
          if (res.data.is_err) {
            this.setNotificationDialog('錯誤訊息-取得標籤定義請求失敗，後台回報錯誤，因此無法進行任何標籤相關管理操作: ' + res.err)
            this.isLoading = false
          } else {
            this.getActionSuperMapStatus = true
            // console.log(res.data.result)
            this.actionSuperMapObj = res.data.result
            this.superAction_tag1_product_code_actions = this.actionSuperMapObj.t_1
            this.superAction_tag2_operation_actions = this.actionSuperMapObj.t_2
            this.superAction_tag3_detail_actions = this.actionSuperMapObj.t_3
            this.superAction_tag4_place_actions = this.actionSuperMapObj.t_4
            this.superAction_tag5_product_actions = this.actionSuperMapObj.t_5
            // console.log(this.superAction_tag1_product_code_actions)
            this.setNotificationDialog('成功取得標籤定義請求')
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          this.setNotificationDialog('取得標籤定義請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else if (err.request) {
          this.setNotificationDialog('取得標籤定義請求有成立，但沒有獲得後端伺服回應，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else {
          this.currentNoteRecordID = ''
          this.setNotificationDialog('取得標籤定義請求建立失敗，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        }
      })
      if (this.selectTypeState === '產品批號') {
        this.coefActive = false
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_1
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作大項') {
        this.coefActive = false
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_2
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作細節') {
        this.coefActive = true
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_3
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作地點') {
        this.coefActive = false
        this.areaActive = true
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_4
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '產品名稱') {
        this.coefActive = false
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_5
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '超級標籤') {
        this.superData = this.actionSuperMapObj.s
        this.superShow = true
        this.actionShow = false
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      }
    },
    async hardDeleteAction (id) {
      this.isLoading = true
      await UserService.HardDeleteNormalAction(id).then(
        res => {
          this.showDeleteActionByIdConfirmDialog = false
          if (res.data.is_err) {
            this.setNotificationDialog('錯誤訊息-刪除指定id一般標籤請求失敗，後台回報錯誤: ' + res.err)
            this.isLoading = false
          } else {
            // console.log(res.data.result)
            this.setNotificationDialog('成功刪除指定id一般標籤請求')
            this.reloadPage('成功刪除指定' + id + '一般標籤')
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          this.showDeleteActionByIdConfirmDialog = false
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('刪除指定id一般標籤請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        } else if (err.request) {
          this.showDeleteActionByIdConfirmDialog = false
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('刪除指定id一般標籤請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        } else {
          this.showDeleteActionByIdConfirmDialog = false
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('刪除指定id一般標籤請求建立失敗，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        }
      })
    },
    async hardDeleteSuperAction (id) {
      this.isLoading = true
      await UserService.HardDeleteSuperInfoToAppBackendToBuildTask(id).then(
        res => {
          this.showDeleteSuperActionByIdConfirmDialog = false
          if (res.data.is_err) {
            this.setNotificationDialog('錯誤訊息-刪除指定id超級標籤請求失敗，後台回報錯誤: ' + res.err)
            this.isLoading = false
          } else {
            // console.log(res.data.result)
            this.setNotificationDialog('成功刪除指定id超級標籤請求')
            this.reloadPage('成功刪除指定' + id + '超級標籤')
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          this.showDeleteActionByIdConfirmDialog = false
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('刪除指定id超級標籤求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        } else if (err.request) {
          this.showDeleteActionByIdConfirmDialog = false
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('刪除指定id超級標籤請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        } else {
          this.showDeleteActionByIdConfirmDialog = false
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('刪除指定id超級標籤請求建立失敗，請回報給管理人員，勿繼續連續刪除')
          this.isLoading = false
          return false
        }
      })
    },
    async getAppAuth () {
      this.isLoading = true
      await AuthServiceApp.login().then(
        res => {
          if (res.is_err) {
            this.setNotificationDialog('錯誤訊息-取得App端驗證請求，後台回報錯誤，因此無法進行任何標籤相關管理操作: ' + res.err)
            this.isLoading = false
          } else {
            this.getAppAuthStatus = true
            this.setNotificationDialog('成功取得App端驗證，可對標籤進行操作，驗證按鈕更新App驗證，或清除來刪去App驗證')
            this.isLoading = false
          }
        }
      ).catch(err => {
        if (err.response) {
          this.setNotificationDialog('取得App端驗證請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else if (err.request) {
          this.setNotificationDialog('取得App端驗證請求有成立，但沒有獲得後端伺服回應，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else {
          this.currentNoteRecordID = ''
          this.setNotificationDialog('取得App端驗證請求建立失敗，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        }
      })
    },
    async removeAppAuth () {
      // console.log(localStorage.getItem('user-app'))
      if (localStorage.getItem('user-app') === null) {
        this.setNotificationDialog('本身無App端驗證，因此無須清除，驗證按鈕可重新取得App驗證')
      } else {
        AuthServiceApp.logout()
        this.setNotificationDialog('清除App端驗證，將無法標籤進行操作，驗證按鈕可重新取得App驗證')
      }
    },
    handleExcelActionDefineFileUpload (event) {
      this.selectedFile = event.target.files[0]
    },
    showUploadActionDefineExcelDialog () {
      // 點進來就重新開始，將之前選的檔案清空
      this.selectedFile = null
      this.showActionDefineUploadExcelDialogToApp = !this.showActionDefineUploadExcelDialogToApp
    },
    async uploadActionDefineExcelToApp () {
      if (!this.selectedFile) {
        this.setNotificationDialog('當前尚未選擇任何.xlsx的Excel檔案，無法進行上傳(注意要符合格式)')
        return // 如果没有選擇檔案，則直接返回沒有作用
      }
      this.isLoading = true
      await UserService.UploadActionDefineInExcel(this.selectedFile).then(
        res => {
          const result = res.data
          this.isLoading = false
          if (result.is_err) {
            this.setNotificationDialog('錯誤訊息: 標籤定義Excel檔案後端接收後出現問題: ' + result.err)
            this.showActionDefineUploadExcelDialogToApp = false
          } else {
            this.setNotificationDialog('此標籤定義Excel檔案成功匯入後端，更新標籤內容')
            this.showActionDefineUploadExcelDialogToApp = false
            this.reloadPage('成功上傳一般標籤定義')
          }
        }
      ).catch(err => {
        // console.log('Auth err')
        // console.log('Auth err')
        if (err.response) {
          // Request made and server responded 請求成立且回應有回來，是錯誤情況
          // 若沒有要鎖住畫面則可以註解或清除掉
          this.setNotificationDialog('標籤定義Excel檔案上傳做更新標籤請求有成立且有收到回應，但回應狀態為錯誤情況，請回報給管理人員，勿繼續連續上傳')
          this.showActionDefineUploadExcelDialogToApp = false
          this.isLoading = false
          return false
        } else if (err.request) {
          // The request was made but no response was received 請求成立但沒有回應來，是錯誤情況
          this.setNotificationDialog('標籤定義Excel檔案上傳做更新標籤請求有成立，但沒有獲得後端伺服回應，請回報給管理人員，勿繼續連續上傳')
          this.showActionDefineUploadExcelDialogToApp = false
          this.isLoading = false
          return false
        } else {
          // Something happened in setting up the request that triggered an Error 請求沒成立自然沒回應，是錯誤情況
          this.setNotificationDialog('標籤定義Excel檔案上傳做更新標籤請求建立失敗，請回報給管理人員，勿繼續連續上傳')
          this.showActionDefineUploadExcelDialogToApp = false
          this.isLoading = false
          return false
        }
      })
    },
    async getActionExcelDefineFromApp () {
      this.isLoading = true
      if (localStorage.getItem('user-app') === null) {
        this.setNotificationDialog('無App端驗證，驗證按鈕可重新取得App驗證')
        this.isLoading = false
        return
      }
      await UserService.GetActionDefineInExcel().then(
        res => {
          // 下面這個將回傳的response轉換成URL，將response的檔案數據以Blob(這可以承接檔案或者二進檔案)
          // 要設置正確MME type: browser才能呼叫正確app來開啟(開啟app也能配合知道是什麼格式，相容性才會好)，後面charset則是設置utf-8編碼(一般這個編碼較不會出錯)
          // 若採用的是.xls則是另一種MME，有需要可以去查詢使用
          const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' })
          // 然後生成一個a 連結元素
          const link = document.createElement('a')
          // 將之前的url注入給此連結元素
          link.href = url
          // 設定此連結屬性，為下載以及值(下載名稱)
          link.setAttribute('download', 'action_define.xlsx')
          // 接著將上面處理好的原件append到body之中，並且模仿點擊事件觸發這個下載動作
          document.body.appendChild(link)
          link.click()
          // 使用完畢將link <a>清除，並且結束鎖住畫面
          document.body.removeChild(link)
          this.isLoading = false
        }
      ).catch(err => {
        if (err.response) {
          this.setNotificationDialog('下載標籤定義Excel內容與標準格式請求有成立且有收到回應，但回應狀態為錯誤情況，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else if (err.request) {
          this.setNotificationDialog('下載標籤定義Excel內容與標準格式請求有成立，但沒有獲得後端伺服回應，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        } else {
          this.currentNoteRecordID = ''
          this.setNotificationDialog('下載標籤定義Excel內容與標準格式請求建立失敗，可嘗試重新請求或回報給管理人員')
          this.isLoading = false
          return false
        }
      })
    },
    setNotificationDialog (content) {
      this.showNotificationDialog = true
      this.formattedMessage = content
    },
    async typeSelectEvent () {
      // 下面這個是點擊縮、放選單
      this.typeFilterActive = !this.typeFilterActive
      // 選後把把選擇狀態顯示出來
      this.selectTypeState = event.target.innerText
      if (this.selectTypeState === '產品批號') {
        this.coefActive = false
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_1
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作大項') {
        this.coefActive = false
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_2
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作細節') {
        this.coefActive = true
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_3
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '操作地點') {
        this.coefActive = false
        this.areaActive = true
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_4
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '產品名稱') {
        this.coefActive = true
        this.areaActive = false
        this.superShow = false
        this.actionShow = true
        this.actionData = this.actionSuperMapObj.t_5
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      } else if (this.selectTypeState === '超級標籤') {
        this.superData = this.actionSuperMapObj.s
        this.superShow = true
        this.actionShow = false
        // 詳細說明初始化，縮起來
        this.detailActive = false
        this.pointDown = true
      }
    },
    actionDetailToggle () {
      this.pointDown = !this.pointDown
      this.detailActive = !this.detailActive
    }
    // showSuperActionCreateDialog () {
    //
    // }
  }
}
</script>

<style scoped>
.word-auto-break {
  word-wrap:break-word
}
.custom-table-inside {
  background-color: #8b8b8b;
  height: 500px;
}
/* class scrollbar背景設置 */
.tableScrollbar::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  /*底色*/
  background: #F5F5F5;
  /*漸層變化*/
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar滑動controller設置 */
.tableScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 150px;
  background-color: #413960;
}
/* class scrollbar corner設置(上下與左右滑動條會有個交錯正方形空白，設置這個才能處理 */
.tableScrollbar::-webkit-scrollbar-corner{
  background: #F5F5F5;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
/* class scrollbar使用提示，使用拉動時候會變色 */
.tableScrollbar::-webkit-scrollbar-thumb:hover {
  background: #85d0a0;
}
</style>
