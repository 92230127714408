import axios from 'axios'
import Config from '@/config/config'
import authHeader from './auth-header'
const API_URL = Config.basicBackendBaseURL

class AuthServiceApp {
  login (user) {
    const headerConfig = authHeader()
    headerConfig.Accept = 'application/json'
    return axios.get(
      API_URL + 'service/app-bearer-auth', {
        headers: headerConfig
      }
    ).then(res => {
      // // console.log('saving token0')
      if (res.data.result) {
        // 這邊將收到的json data取出放在localstorage名叫user的 key中(他要將它字串化取出)
        // 到時候要取出token應該就得以accessToken配合user
        // // console.log('saving token1')
        localStorage.setItem('user-app', JSON.stringify(res.data.result))
      }
      // 最終都會回傳一個res.data出去(其實不用應該也OK ...但有這個可以看是否成功)
      return res.data
    })
  }

  logout () {
    localStorage.removeItem('user-app')
  }
}
export default new AuthServiceApp()
